import { makeStyles } from "@material-ui/core/styles";


const SearchingStyles = makeStyles((theme) => ({
  searchInner: {
    padding: "0px 100px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  header: {
    height: 180,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      height: 120,
    },
  },
  logo: {
    paddingBottom: 0,
    height: 90,
    textAlign: "center",
    position: "relative",
    zIndex: 2,
    marginBottom: 48,
    // left: -7,
    "& img": {
      maxHeight: "80%",
      maxWidth: "80%",
      // [theme.breakpoints.down("xs")]: {
      //   maxHeight: "80%",
      //   maxWidth: "90%",
      // },
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
      height: 80,
      left: 0,
    },
  },
  searchContainer: {
    marginTop: 150,
    justifyContent: "center",
    flexWrap: "unset",
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      flexDirection: "column",
    }
  },
  formsFields: {
    display: "flex",
  },
  itemField: {
    margin: "0px 7px",
    "& fieldset": {
      borderRadius: 25,
      borderColor: "#8A8A8A890",
    },
    "& .MuiInputBase-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root:hover": {
      "& fieldset": {
        borderColor: theme.palette.primary.main
      },
    },
    "& .MuiSelect-select:focus": {
      borderRadius: 25,
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
      borderRadius: "25px 0px 0px 25px"
    },
    [theme.breakpoints.down("xs")]: {
      margin: "7px 0px",
    }
  },
  imgLabel: {
    height: 30,
    marginRight: 5,
    width: 30,
  },
  fieldSearch: {
    width: "100%",
    maxWidth: 485,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
    },
  },
  fieldCarrier: {
    width: "100%",
    maxWidth: 170,
    "& .MuiOutlinedInput-input": {
      padding: "4px 14px",
      height: 47,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
    },
  },
  formsBtn: {
    width: 100,
    height: "60px",
    color: "#f9f9f9 !important",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& a:-webkit-any-link": {
      color: "#878787",
    },
    activeLink: {
      "& a:-webkit-any-link": {
        color: "#fff",
      },
    },
    "& button": {
      width: 100,
      height: "100%",
      borderRadius: 25,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: 55
      },
    }
  },
  activeFormBtn: {
    width: 100,
    height: "60px",
    color: "#f9f9f9 !important",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& a:-webkit-any-link": {
      color: "#fff",
    },
    "& button": {
      width: 100,
      height: "100%",
      borderRadius: 25,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: 55
      },
    }
  },
  menuPopover: {
    borderRadius: 25,
    height:55,
    "&:last-child": {
      display: "none",
    }
  },
  menuPopoverWithError: {
    borderRadius: 25,
    height:55,
    "&:last-child": {
      display: "block",
    }
  },
}));

export default SearchingStyles;
