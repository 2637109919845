import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const dropOffReceiptStyles = makeStyles((theme: Theme) =>
  createStyles({
    address: {
      fontFamily: "'Scada', sans-serif",
      fontStyle: "inherit",
      "& a": {
        color: "inherit"
      }
    },
    bold: {
      fontWeight: "bold"
    },
    divTbody: {
      display: "table-row-group",
      width: "100%"
    },
    divTd: {
      display: "table-cell",
      width: "50%",
      verticalAlign: "middle"
    },
    divThead: {
      display: "table-header-group"
    },
    divTr: {
      display: "table-row"
    },
    ellipsize: {
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    header: {
      height: 180,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        height: 120,
      },
    },
    item: {
      marginBottom: "0.875rem",
      maxWidth: "100%"
    },
    logo: {
      paddingBottom: 0,
      height: 90,
      textAlign: "center",
      position: "relative",
      zIndex: 2,
      marginBottom: 48,
      "& img": {
        maxHeight: "80%",
        maxWidth: "80%",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: 16,
        height: 80,
        left: 0,
      },
    },
    pieceList: {
      paddingLeft: "1rem",
      paddingRight: "0.875rem",
      marginTop: "0.1rem"
    },
    receiptInfoContainer: {
      '& .receipt-info-container-child': {
        width: '50%',
        paddingTop: '1em'
      },
      width: '40em',
      maxWidth: '100%',
      padding: '1em'
    },
    receiptWrapper: {
      maxWidth: '100%',
      width: '40rem',
      padding: '0.875em 0'
    },
    searchContainer: {
      marginTop: 150,
      justifyContent: "center",
      flexWrap: "unset",
      [theme.breakpoints.down("xs")]: {
        marginTop: 20,
        flexDirection: "column",
      }
    },
    searchInner: {
      padding: "0px 100px",
      [theme.breakpoints.down("xs")]: {
        padding: "0px",
      },
    },
    sentiment: {
      display: 'flex',
      alignItems: 'center',
      '& > button': {
        height: '100%'
      },
    },
    trackingNumberSection: {
      display: "table",
      width: "100%",
      tableLayout: "fixed"
    }
  })
);