import React from "react";
import { useLocation } from "react-router-dom";

/***
 * Custom hook that returns query parameters from the current URL
 */
export default function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}