import React, { PropsWithChildren } from "react";
import { Typography, Box, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button, useMediaQuery } from "@material-ui/core";
import ITrack, { AddressType, CarrierNames } from "../../types/Track";
import TrackingListStyles from "../Styles/TrackingListStyles";
import { useHistory } from "react-router-dom";

interface MultipleResultsProps {
  details: ITrack[];
  carrier: CarrierNames;
  trackingNumber: Number;
}

interface SummaryListProps {
  details: ITrack[];
}

function addressToString(address: AddressType | undefined | null) {
  if (!address) {
    return '';
  }
  return Object.values(address).map((address) => address?.trim()).filter((address) => Boolean(address)).join(', ');
}

function RadioItem(details: ITrack[]): React.ReactNode {
  return details.map((detailObject) => (
    <FormControlLabel key={detailObject.TrackingNumberUniqueIdentifier} control={<Radio required aria-required />} value={detailObject.TrackingNumberUniqueIdentifier} label={`${detailObject.TrackingNumber} - ${addressToString(detailObject.DestinationAddress) || 'UNKNOWN'}`} />
  ));
}

const SummaryList: React.FC<SummaryListProps> = (props: PropsWithChildren<SummaryListProps>) => {
  const { details } = props;

  return (
    <div>
      <Grid direction="row" container>
        <Grid xs={12} item style={{ display: "flex", }}>
          <FormControl component={"fieldset"} >
            <RadioGroup aria-label="List of shipments that match tracking number" name="tracking_numbers" >
              {RadioItem(details)}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}

/**
 * Displays all the details of colliding tracking numbers as a form containing
 * radio buttons
 */
const DuplicateTrackingNumberList: React.FC<MultipleResultsProps> = (props: PropsWithChildren<MultipleResultsProps>) => {
  const { details, trackingNumber, carrier } = props;
  const classes = TrackingListStyles();
  const mobileQuery = useMediaQuery("(max-width:600px)");
  const history = useHistory();

  const handleSubmit: React.MouseEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const identifier = document.querySelector<HTMLInputElement>('input[name="tracking_numbers"]:checked')?.value;
    return history.push(`/${carrier}/${trackingNumber}/${identifier}`);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.trackListContainer}>
        <div className={classes.trackList}>

          <Box style={{ padding: "10px 0px" }}>
            <Typography component="h1" variant={mobileQuery ? "h4" : "h3"}>
              We found multiple waybills matches. Locate your shipment by destination
            </Typography>

            <SummaryList details={details} />
          </Box>

          <Box style={{ padding: "10px 0px" }}>
            <Button
              className={classes.activeFormBtn}
              variant="contained"
              color="primary"
              type="submit"
            >
              Resolve
            </Button>
          </Box>
        </div>
      </Box>
    </form>
  )
}

export default DuplicateTrackingNumberList;
