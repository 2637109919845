import { call, takeEvery, all } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  LoadTrackReturnType,
  // setError,
  // setIsFetching,
  // setTrack,
} from "./tracking.actions";
import { LOAD_TRACK } from "./tracking.types";
import trackApi from "../../api/track.api";

export function* loadTrackWorker({
  payload,
}: LoadTrackReturnType): SagaIterator {
  console.log("1");
  // yield put(setIsFetching(true));
  // yield put(setError(""));
  console.log("got it");
  try {
    const response = yield call(
      trackApi.getTrack,
      payload.trackId,
      payload.career
    );
    console.log("response called from saga");
    console.log(response);
    if (response) {
      // yield put(setTrack(response));
    } else {
      console.log("gotcha");
      // yield put(setTrack(null));
      // yield put(setError("Cannot get this track. Link is not correct."));
    }
  } catch (e) {
    // yield put(setTrack(null));
    // yield put(setError("Cannot get this track. Link is not correct."));
  }
  // yield put(setIsFetching(false));
}

export default function* trackingSaga(): SagaIterator {
  yield all([takeEvery(LOAD_TRACK, loadTrackWorker)]);
}
