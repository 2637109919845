import React from "react";
import "./theme/styles.css";
// import { useDispatch, useSelector } from "react-redux";
// import { LinearProgress } from "@material-ui/core";
import Router from "./Router";
// import { getInitialized } from "./redux/app/app.selectors";
// import { AppStateType } from "./redux/root.reducer";
// import { initializeApp } from "./redux/app/app.actions";

const App: React.FC = React.memo(() => {
  // const dispatch = useDispatch();
  // const initialized = useSelector<AppStateType>((state) =>
  //   getInitialized(state)
  // );
  //
  // useEffect(() => {
  //   if (!initialized) {
  //     dispatch(initializeApp());
  //   }
  // }, [initialized, dispatch]);
  //
  // if (!initialized) return <LinearProgress />;
  return <Router />;
});

export default App;
