export enum StatusEnum {
  PreTransit = "PreTransit",
  InTransit = "InTransit",
  OutForDelivery = "OutForDelivery",
  Delivered = "Delivered",
  Delayed = "Delayed",
  Exception = "Exception",
  Error = "Error",
  Unknown = "Unknown",
  Cancelled = "Cancelled",
  AvailableForPickup = "AvailableForPickup",
  ReturnToSender = "ReturnToSender",
  StatusInfo = "StatusInfo",
}

export type AddressType = {
  City: string;
  StateOrProvinceCode: string;
  Country: string;
  PostalCode: string | null;
};

export type TrackingEventsType = {
  DateTime: string;
  EventCode: StatusEnum;
  CarrierEventCode: string;
  EventDescription: string;
  Location: string;
};

export type CarrierNames = "FedEx" | "DHL" | "UPS";

// export type validateTrackType = {
//   Status: string;
//   Carrier: string;
//   NumberType: string;
// };


export default interface ITrack {
  TrackingNumberUniqueIdentifier: string;
  TrackingNumber: string;
  DeliveryTimestamp?: string;
  EstimatedDeliveryDate?: string;
  DeliverySignatureName: string;
  ShipmentInfo?: {
    NumberOfPieces: number;
    ShipmentWeight: {
      Units: string;
      Value: string;
    };
    PackagingInfo: string;
    ShipperAddress: AddressType;
    DestinationAddress: AddressType;
    Carrier: CarrierNames;
    ServiceInfo: string;
  };
  StatusCode: string;
  CarrierStatusCode: string;
  StatusDescription: string;
  ReturnTrackingNumber: string;
  TrackingEvents: TrackingEventsType[];
  DestinationAddress?: AddressType;
}
