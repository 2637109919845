import { Box, Grid, Paper, Typography, Button } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";

type TrackingNumberErrorProps = {
  boxClassName: string;
  containerClassName: string;
  errorMessageClassName: string;
  error: string;
};

/**
 * Displays an error message
 */
const TrackingNumberError: FC<TrackingNumberErrorProps> = (
  props: TrackingNumberErrorProps
) => {
  const {
    boxClassName,
    containerClassName,
    errorMessageClassName,
    error,
  } = props;

  return (
    <Box className={boxClassName}>
      <Grid container>
        <Grid item className={containerClassName}>
          <Paper className={errorMessageClassName}>
            <Typography variant="h4" style={{ fontSize: 18 }}>
              {error}
            </Typography>
          </Paper>
          <Link to="/">
            <Button variant="contained" color="primary">
              Track another package
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TrackingNumberError;
