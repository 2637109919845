import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { LeftArrow } from "../assets/images/icons";

type BackButtonProps = {
  className: string;
};

const BackButton: FC<BackButtonProps> = (props: BackButtonProps) => {
  const { className } = props;

  return (
    <Box className={className}>
      <Link to="/">
        <LeftArrow />
      </Link>
      <Typography variant="body1" style={{ fontSize: 14 }}>
        Track another package
      </Typography>
    </Box>
  );
};

export default BackButton;
