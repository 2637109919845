import { TrackingEventsType } from "../types/Track";

import reverseArr from "./reverse";

const sortForStatusInfo = (
  events: TrackingEventsType[]
): TrackingEventsType[] => {
  const eventsArray = [...events];

  return reverseArr(eventsArray);
};

export default sortForStatusInfo;
