/**
 * Return the human readable name of a carrier service name. It will
 * convert something like FedExGround to FedEx Ground
 * @param serviceName 
 * @returns 
 */
function readableServiceName(serviceName: string) {
    const re = /FedEx|DHL|[A-Z][a-z]+|\d+/g;
    return serviceName.match(re)?.join(" ") || "";
}

export { readableServiceName }