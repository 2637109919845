import { makeStyles } from "@material-ui/core/styles";
// import pattern from "../../assets/images/pattern-2.svg";
// import { useMediaQuery } from "@material-ui/core";


const TrackingStyles = makeStyles((theme) => ({
  body: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    overflow: "auto",
    left: 0,
    top: 0,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#f9f9f9",
    // backgroundImage: `url(${pattern})`,
    backgroundSize: 20,
    "& .MuiContainer-root" : {
      paddingLeft: 35,
      paddingRight: 35,
      [theme.breakpoints.down("xs")]: {
        marginBottom: 100,
      },
    },
  },
  preloader: {
    position: "fixed",
    top: "50%",
    left: "50%",
    marginLeft: "-50px",
    marginTop: "-50px",
    width: "100px !important",
    height: "100px !important",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  mainGridContainer: {
    // height: "100vh",
    alignItems: "center",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      alignContent: "flex-start",
    },
    // paddingTop: "30px",
    // boxShadow: theme.shadows[7],
  },
  mainShadow: {
    boxShadow: theme.shadows[7],
  },
  view: {
    [theme.breakpoints.down("sm")]: {
      left: "-200%",
      position: "absolute",
      height: "calc(100vh - 180px)",
      width: "90%",
      bottom: 0,
      overflow: "auto",
      paddingBottom: theme.spacing(2),
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  visible: {
    [theme.breakpoints.down("sm")]: {
      left: "5%",
    },
  },
  boxInner: {
    padding: "0px 100px",
    boxShadow: theme.shadows[7],
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  header: {
    height: 180,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      height: 152,
      // alignItems: "center",
    },
  },
  backBtn: {
    width: "100%",
    minWidth: 150,
    position: "absolute",
    top: 10,
    left: 10,
    display: "flex",
    alignItems: "center",
    color: "#071D49",
    [theme.breakpoints.down("xs")]: {
      // position: "unset",
      // marginBottom: 20,
      top: -50,
      left: "16%",
    },
    "& a": {
      width: 36,
      height: 36,
      border: "1px solid #071D49",
      background: "#FFFFFF",
      cursor: "pointer",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      marginLeft: 10,
      [theme.breakpoints.down("xs")]: {
        width: 32,
        height: 32,
        marginLeft: 10,
      },
    },
    "& p": {
      [theme.breakpoints.down("xs")]: {
        // display: "none",
      },
    }
  },
  logo: {
    paddingBottom: 0,
    height: 90,
    textAlign: "center",
    position: "relative",
    zIndex: 2,
    marginBottom: theme.spacing(4),
    "& img": {
      maxHeight: "80%",
      maxWidth: "80%",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "80%",
        maxWidth: "90%",
        // paddingLeft: 30,
      },
    },
    [theme.breakpoints.down("xs")]: {
      height: 80,
      // marginBottom: theme.spacing(0),
    },
  },
  tabs: {
    marginBottom: theme.spacing(2),
    "& *": {
      color: theme.palette.primary.main,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  stepper: {
    // maxWidth: 700,
    margin: "0 auto",
    borderRadius: theme.shape.borderRadius,
    padding: "20px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px 20px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px 10px",
    },
    "& .MuiStepLabel-iconContainer": {
      height: 48,
      width: 48,
      borderRadius: "50%",
      position: "relative",
      zIndex: 2,
    },
    "& .MuiStepConnector-alternativeLabel": {
      top: 23,
      left: "-50%",
      width: "100%",
      position: "absolute",
    },
    "& .MuiStepConnector-line": {
      height: 3,
      border: "none",
      backgroundColor: "#EFEFEE",
    },
    "& .MuiStep-root": {
      "& .MuiStepLabel-iconContainer": {
        background: "#FFFFFF",
        // background: "#ffff00",
        border: `2px solid ${theme.palette.primary.main}`
      },
      "& .Mui-disabled": {
        "& .MuiStepLabel-iconContainer": {
          border: "2px solid #EFEFEE !important",
          background: "#FFFFFF",
        },
      },
      "& .MuiStepConnector-completed": {
        "& .MuiStepConnector-line": {
          backgroundColor: theme.palette.primary.main,
          border: "none",
        },
        "& .MuiStepLabel-root  .MuiStepLabel-iconContainer": {
          background: theme.palette.primary.main,
        },
      },
    },
    "& .MuiStep-completed": {
      "& .MuiStepLabel-iconContainer": {
        background: `${theme.palette.primary.main} !important`,
      },
    },
    "& .MuiStepConnector-active": {
      "& .MuiStepConnector-line": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .MuiStepConnector-vertical": {
      padding: 0,
      marginLeft: 6,
    },
    "& .MuiStepLabel-vertical": {
      margin: "-5px 0",
    },
    "& .MuiStepConnector-lineVertical": {
      width: 3,
      border: "none",
      backgroundColor: "#EFEFEE",
    },
    "& .MuiStepIcon-root": {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      "&.MuiStepIcon-active": {
        color: theme.palette.primary.main,
      },
      "&.MuiStepIcon-completed": {
        color: theme.palette.primary.main,
      },

      color: theme.palette.text.secondary,

      "& text": {
        display: "none",
      },
      "& path": {
        display: "none",
      },
    },
    "& .MuiStepLabel-label": {
      fontWeight: 500,
      fontSize: 17,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      }
    },
  },
  stepLabel: {
    "& .MuiStepLabel-iconContainer": {
      backgroundColor: theme.palette.divider,
    },
    [theme.breakpoints.down("sm")]: {
      "& *": {
        //fontSize: `${theme.typography.body1.fontSize}px`,
        fontsize: '2.1875rem',
      },
    },
  },
  stepCompleted: {
    "& .MuiStepLabel-active": {
      color: "rgb(39, 174, 96)",
      fontWeight: "bold",
    },
  },
  stepError: {
    "& .MuiStepLabel-iconContainer":{
      borderColor: "#f12323 !important",
      background: "#f12323 !important",
    },
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      color: "#f12323",
      fontWeight: "Bold"
    },
    "& svg": {
      fill: "#FFFFFF",
    },
  },
  stepErrorLineGradient1: {
    "& .MuiStepConnector-line": {
      background: "linear-gradient(90deg, #f12323 0%, #EFEFEE 97.33%) !important",
    },
  },
  stepErrorLineGradient2: {
    "& .MuiStepConnector-line": {
      background: "linear-gradient(90deg, #071D49 0%, #f12323 97.33%) !important",
    },
  },
  stepIconRoot: {
    fill: "#D6D6D6",
    position: "absolute",
    top: 12,
    left: 12,
  },
  stepIconActive: {
    fill: "#071D49",
  },
  stepIconCompleted: {
    fill: "#FFFFFF",
  },
  stepItem: {

  },
  stepItemDelivered: {
    "& .MuiStepLabel-iconContainer": {
      background: "rgb(39, 174, 96) !important",
      border: "2px solid rgb(39, 174, 96) !important"
    },
    "& svg": {
      fill: "#FFFFFF",
    },
    "& .MuiStepConnector-line": {
      background: "linear-gradient(90deg, #071D49 0%, rgb(39, 174, 96) 97.33%)",
    },
  },
  stepItemLineGradient: {
    "& .MuiStepConnector-line": {
      background: "linear-gradient(90deg, #071D49 0%, #EFEFEE 97.33%)",
    },
  },

  trackingListInner: {
    padding: "50px 100px",
    position: "relative",
    boxShadow: theme.shadows[7],
    [theme.breakpoints.down("sm")]: {
      padding: 28,
    },
    "& hr": {
      marginTop: 50,
      border: "none",
      color: "#D6D6D6",
      backgroundColor: "#D6D6D6",
      height: 1,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 28,
      },
    }
  },
  containerInfo: {
    padding: "10px 50px 10px 50px",
    // borderTop: "1px solid #EFEFEE",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 40px",
      // padding: "20px",
      // borderTop: "unset",
    },
  },
  preInfo: {
    border: 0,
    // padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      // borderTop: "1px solid #EFEFEE",
      padding: 0
    },
    "& .MuiGrid-item": {
      verticalAlign: "bottom",
      padding: theme.spacing(2),
      paddingLeft: 0,
    },
  },
  preInfo__emptyGrid: {
    padding: "0 !important",
  },

  afterWords: {
    textAlign: "left",
    backgroundColor: "#FFFFFF",
    color: "#666666",
    fontFamily: "'Scada', sans-serif",
    padding: "0px 20px",
    position: "absolute",
    top: 48,
    left: 190,
    [theme.breakpoints.down("sm")]: {
      top: 30, 
      left: 80,
    },
    [theme.breakpoints.down("xs")]: {
      top: 30, 
      left: 45,
      fontSize: 11,
    },
  },
  nothing: {
    letterSpacing: "inherit",
  },
  carrierLogo: {
    textAlign: "left",
    padding: 0,
    // height: 90,
    margin: "auto auto",
    // maxWidth: 330,
    "& img": {
      maxWidth: "70%",
      maxHeight: "100%",
      height: "auto",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "140px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "80%",
    },
  },
  trackingNumber: {
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      // flexDirection: "column",
    },
  },
  trackingNumberItem1: {
    paddingRight: 10,
    minWidth: 150,
    width: "100%",
    "& h3": {
      [theme.breakpoints.down("xs")]: {
        marginBottom: 10,
      },
    }
  },
  trackingNumberItem2: {
    "& h3": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
        marginBottom: 10,
      },
    }
  },
  trackingItem: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
  },
  itemOrigin: {
    marginTop: 5,
    marginBottom: 5,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  message: {
    position: "fixed",
    right: 50,
    bottom: 50,
    zIndex: 10,
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      right: 10,
      bottom: 20,
    },
  },
  errorContainer: {
    top: "50%",
    left: "50%",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection:"column",
    "& a": {
      width: "100%",
    },
    "& button": {
      width: "100%",
      borderRadius: 25
    },
  },
  errorMessage: {
    // padding: theme.spacing(2),
    padding: "30px",
    marginBottom: 20,
    textAlign: "center",
  },
  errorInvalidUrl: {
    padding: "30px 40px",
    top: "50%",
    left: "50%",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  errorH3: {
    fontSize: "28px",
  },
  backToTop: {
    position: "absolute",
    width: 40,
    height: 40,
    border: "2px solid #FFFFFF",
    background: "#071D49",
    cursor: "pointer",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bottom: 37,
    left: 190,
    [theme.breakpoints.down("sm")]: {
      bottom: 36, 
      left: 80,
    },
    [theme.breakpoints.down("xs")]: {
      bottom: 36, 
      left: 50,
    },
    '&:focus' : {
      outline: "none",
    },
    '&:-moz-focus-inner': {
      outline: "none",
    }
  },
}));

export default TrackingStyles;
