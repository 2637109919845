import { Box, Button, Container, Grid, Slide, Typography } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { FC, useEffect, useState } from "react";
import useQuery from "../hooks/useQuery";

type FeedbackReceivedProps = {
  happy?: boolean;
}

const FeedbackReceived: FC<FeedbackReceivedProps> = (props) => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const queryParams = useQuery();

  useEffect(() => {
    const timer = setTimeout(() => { setOpenAlert(true) }, 1000);
    return () => clearTimeout(timer);
  }, [openAlert]);


  return (
    <Container maxWidth='sm'>
      <Grid container>
        <Grid item xs={12}>
          {
            openAlert ?
              <Slide in={openAlert}>
                <Alert
                  severity="success"
                  action={
                    <Button
                      size="small"
                      variant="outlined"
                      style={{ 'color': '#FFFFFF' }}
                      onClick={() => window.location.assign(`https://goship.cc/api/redirectToRating?id=${queryParams.get('id')}`)}
                    >
                      Happy To!
                    </Button>
                  }
                  variant="filled">
                  <Typography>
                    If you have a moment, we'd love for you to leave us a review on Google
                  </Typography>
                </Alert>
              </Slide> :
              null
          }

          {
            props.happy ?
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                style={{ width: '100%', height: '100vh' }}>
                <div>
                  <CheckCircleOutline style={{ fontSize: 96 }} />
                </div>
                <Typography component={'h1'} variant={'h1'}>
                  Thank You!
                </Typography>
                <Typography>
                  We're glad you enjoyed your experience
                </Typography>
              </Box> :
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                style={{ width: '100%', height: '100vh' }}>
                <div>
                  <CheckCircleOutline style={{ fontSize: 96 }} />
                </div>
                <Typography component={'h1'} variant={'h1'}>
                  Thank You!
                </Typography>
                <Typography>
                  We'll do better next time
                </Typography>
              </Box>
          }
        </Grid>
      </Grid>
    </Container>
  )
}

export default FeedbackReceived;
