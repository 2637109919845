import { Box, Typography, Divider, Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { FC } from "react";
import { DropOffTransactionDetailShipments } from "../../utils/network";
import { dropOffReceiptStyles } from "../Styles/DropOffReceiptStyles";
import TrackingDetails from "./TrackingDetails";

type DropOffReceiptInfoProps = {
  dropoffId: string;
  dropoffDate: string;
  totalPieceCounts: number;
  shipments: DropOffTransactionDetailShipments[];
}

const DropOffReceiptInfo: FC<DropOffReceiptInfoProps> = (props) => {
  const classes = dropOffReceiptStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'center'}>
          <div className={classes.receiptInfoContainer}>
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.item} display={'flex'} justifyContent="center">
                  <Typography variant="h6">Drop Off Receipt <span>{props.dropoffId || <Skeleton variant="text" />}</span></Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Divider className={classes.item} />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item md={6} xs={12} sm={12}>
                <Box>
                  <div className={classes.item}>
                    <Typography variant="h6">Drop Off Date/Time:</Typography>
                    <div><span>{props.dropoffDate || <Skeleton variant="text" />}</span></div>
                  </div>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box>
                  <div className={classes.item}>
                    <Typography variant="h6">Total Pieces:</Typography>
                    <div><span>{props.totalPieceCounts || <Skeleton variant="text" />}</span></div>
                  </div>
                </Box>
              </Grid>
            </Grid>

          </div>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent={'center'}>
          <div className={classes.receiptInfoContainer}>
            <Divider />
            <Box display={'flex'}>
              <div className={classes.trackingNumberSection}>
                <div className={classes.divThead}>
                  <div className={classes.divTr}>
                    <div className={classes.divTd}>
                      <Typography variant="h6">Tracking Number</Typography>
                    </div>
                    <div className={classes.divTd}>
                      <Typography variant="h6">Carrier &amp; Service</Typography>
                    </div>
                  </div>
                </div>

                {<TrackingDetails shipments={props.shipments} />}
              </div>
            </Box>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <div className={classes.receiptInfoContainer}>
            <Divider />
            <Box display={'flex'} style={{ marginTop: "0.875rem" }}>
              <div>
                <div>
                  <Typography variant="h6">Disclaimer</Typography>
                  <Typography component="p">
                    This receipt lists each package received, but it is not confirmation has picked up the package.
                    Services provided for the listed packages are subject to and governed by the Rates and Service
                    Guide.
                  </Typography>
                </div>
              </div>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DropOffReceiptInfo;
