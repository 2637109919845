import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Container,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { Warning } from '@material-ui/icons';
import cn from "classnames";

import logoImg from "../../assets/images/logo.png";
import DHLLogoImg from "../../assets/images/DHLRound.png";
import FedExLogoImg from "../../assets/images/FedExRound.png";
import UPSLogoImg from "../../assets/images/ups-logo.svg";
import SearchingStyles from "./SearchingStyles";
import trackApi from '../../api/track.api';

const UPS = () => {
  const classes = SearchingStyles();
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <img className={classes.imgLabel} src={UPSLogoImg} alt="DHL" />
      <p>UPS</p>
    </span>
  );
};

const DHL = () => {
  const classes = SearchingStyles();
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <img className={classes.imgLabel} src={DHLLogoImg} alt="DHL" />
      <p>DHL Express</p>
    </span>
  );
};

const FedEx = () => {
  const classes = SearchingStyles();
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <img className={classes.imgLabel} src={FedExLogoImg} alt="FedEx" />
      <p>FedEx</p>
    </span>
  );
};

const AutoDetect = () => {
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <p>Auto detect</p>
    </span>
  );
};

const UnknownCarrier = () => {
  return (
    <span style={{ display: "flex", alignItems: "center", color: "red" }}>
      <Warning />
      <p>Unknown Carrier</p>
    </span>
  );
};

const Searching = () => {
  const classes = SearchingStyles();
  const [carrier, setCarrier] = useState("auto");
  const [trackNum, setTrackNum] = useState("");
  const [enabled, setEnabled] = useState<boolean>(true);
  const [autoFocus] = useState<boolean>(true);
  const [error, setError] = useState<string | null>("");
  const history = useHistory();

  useEffect(() => {
    let mounted = true;

    if (trackNum === "") {
      setError(null);
      setCarrier("auto");
      setEnabled(true);
    } else if (trackNum.length) {
      setTimeout(() => {
        trackApi
          .validateTrack(
            trackNum,
            "gogogoshipping.com-fb441bb86161debd71aaccde914b4af2"
          )
          .then((response) => {
            if (mounted) {
              if (!response || response.Status === "UnknownCarrier") {
                setError("No tracking information found at this URL");
                setCarrier("UnknownCarrier");
                setEnabled(true);
              } else {
                setCarrier(response.Carrier);
                setEnabled(false);
                setError(null);
              }
            }
          });
      }, 1000);
    }
    // if (!autoFocus) {
    //   //setEnabled(true);
    //   //setError('Tracking number must be more than 10 characters');
    //   //setCarrier('auto');
    // } else if (trackNum) {
    //
    // }
    return () => {
      mounted = false;
    };
  }, [trackNum, carrier, error]);

  const carrieries = [
    {
      value: "auto",
      label: <AutoDetect />,
    },
    {
      value: "DHL",
      label: <DHL />,
    },
    {
      value: "FedEx",
      label: <FedEx />,
    },
    {
      value: "UPS",
      label: <UPS />,
    },
    {
      value: "UnknownCarrier",
      label: <UnknownCarrier />,
    },
  ];


  const handleCarrier = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCarrier(event.target.value);
  };

  const handleTrack = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currentValue = event.target.value;
    const filteredTrackNum = currentValue.replace(/[^\w\s]/gi, '').trim();
    setTrackNum(filteredTrackNum);
  };

  // Submit form when push Enter
  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>): any => {
    if (e.key === 'Enter') {
      const currentUrl = carrier !== 'auto' ? `${carrier}/${trackNum}` : `${trackNum}`;
      return history.push(currentUrl);
    }
  };

  const handleSubmit = () => {
    const currentUrl = carrier !== 'auto' ? `${carrier}/${trackNum}` : `${trackNum}`;
    return history.push(currentUrl);
  };

  return (
    <Container>
      <Box className={classes.searchInner}>
        <Box className={classes.header}>
          <Box className={classes.logo}>
            <img src={logoImg} alt="GoGoGo logo" />
          </Box>
        </Box>
      </Box>

      <Grid item xs={12} sm={12} container className={classes.searchContainer}>
        <Grid className={cn(classes.itemField, classes.fieldSearch)}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-amount">
              Tracking Number
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              labelWidth={110}
              value={trackNum || ""}
              autoFocus={autoFocus}
              onChange={handleTrack}
              onKeyPress={handleEnterPress}
              //onBlur={() => setAutoFocus(false)}
              //onFocus={() => setAutoFocus(true)}
              endAdornment={
                <ClearIcon 
                style={trackNum ? {cursor:"pointer", fill:"silver"} : {display: "none"}}  
                onClick={() => { setTrackNum("") }} 
                />
              }
            />
            {error
            ? <span style={{ paddingLeft: "14px", marginTop: "5px", color: 'red' }}>{error}</span>
            : null
            }
          </FormControl>
        </Grid>
        
        <Grid className={cn(classes.itemField, classes.fieldCarrier)}>
          <TextField
            id="outlined-select-currency"
            select
            label="Carrier"
            value={carrier || ''}
            onChange={handleCarrier}
            variant="outlined"
            fullWidth
          >
            {carrieries.map((option) => {
              return (
                <MenuItem
                  className={error !== 'No tracking information found at this URL' ? classes.menuPopover : classes.menuPopoverWithError}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              )
            }
            )}
          </TextField>
        </Grid>
        <Grid item className={cn(classes.formsBtn, classes.itemField)}>

          <Button
            className={enabled ? classes.formsBtn : classes.activeFormBtn}
            variant="contained"
            color="primary"
            disabled={enabled}
            onClick={handleSubmit}
          >
            Track
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Searching;
