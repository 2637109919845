import {
  LOAD_TRACK,
  // SET_ERROR,
  // SET_IS_FETCHING,
  // SET_TRACK,
} from "./tracking.types";
import ITrack from "../../types/Track";
import { TrackActionsReturnTypes } from "./tracking.actions";

const initialState = {
  track: null as ITrack | null,
  isFetching: false,
  error: "",
};
export type TrackInitialStateType = typeof initialState;

const trackingReducer = (
  state = initialState,
  action: TrackActionsReturnTypes
): TrackInitialStateType => {
  switch (action.type) {

    case LOAD_TRACK:
      return {
        ...state,
        error: "1",
      };

    // case SET_TRACK:
    //   return {
    //     ...state,
    //     track: action.payload,
    //   };
    //
    // case SET_IS_FETCHING:
    //   return {
    //     ...state,
    //     isFetching: action.payload,
    //   };
    //
    // case SET_ERROR:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};

export default trackingReducer;
