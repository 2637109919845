import { StatusEnum } from "../types/Track";
import theme from "../theme/theme";

const trackStatusColor = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.Delivered:
      return theme.palette.success.main;
    case StatusEnum.AvailableForPickup:
      return theme.palette.success.main;
    default:
      return theme.palette.text.primary;
  }
};

export default trackStatusColor;
