import { AxiosResponse } from "axios";
import instance from "./index";
import ITrack from "../types/Track";
import { ResponseType } from "../types/Response";

const trackApi = {
  /**
   * Gets tracking information from multiship and returns the response
   */
  getTrackingInformationFromCarrier(
    trackId: string,
    carrier?: string,
    identifier = "",
    APIKey = "gogogoshipping.com-fb441bb86161debd71aaccde914b4af2"
  ): Promise<AxiosResponse<ResponseType>> {
    return instance.get<ResponseType>(
      `track/${trackId}?APIKey=${APIKey}${
        carrier ? `&Carrier=${carrier}` : ""
      }${identifier ? `&TrackingNumberUniqueIdentifier=${identifier}` : ""}`
    );
  },

  /**
   * Consumes the response from a request for tracking number information from multiship
   * and converts it into a Tracking object (`ITrack`)
   */
  transformToTrackingObject(
    rawTrackingData: AxiosResponse<ResponseType>
  ): ITrack {
    return rawTrackingData.data.Tracking[0];
  },

  getTrack(
    trackId: string,
    carrier?: string,
    APIKey = "gogogoshipping.com-fb441bb86161debd71aaccde914b4af2"
  ): Promise<ITrack | null> {
    return this.getTrackingInformationFromCarrier(trackId, carrier, APIKey)
      .then(this.transformToTrackingObject)
      .catch((e) => {
        console.log("error from server", e);
        return null;
      });
  },

  validateTrack(
    trackId: string,
    APIKey = "gogogoshipping.com-fb441bb86161debd71aaccde914b4af2"
  ): Promise<ResponseType | null> {
    return instance
      .get<ResponseType>(`track/validate/${trackId}?APIKey=${APIKey}`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log("error from server", e);
        return null;
      });
  },
};

export default trackApi;
