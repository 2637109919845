import { Box, Container, Grid, Paper } from "@material-ui/core";
import React, { FC } from "react";
import ITrack, { CarrierNames } from "../types/Track";
import BackButton from "./BackButton";
import DuplicateTrackingNumberList from "./MultipleResults/DuplicateTrackingNumberList";

type SelectTrackingNumberOptionProps = {
  isMediumScreen: boolean;
  duplicateData: ITrack[];
  carrier: CarrierNames;
  trackingNumber: string;
  logoImage: string;

  bodyClassName: string;
  mainGridContainerClassName: string;
  boxInnerClassName: string;
  headerClassName: string;
  logoClassName: string;
  backButtonClassName: string;
  containerInfoClassName: string;
  infoClassName: string;
}

/**
 * Displays a form that allows a user to select one of at least two tracking numbers
 */
const SelectTrackingNumberOption: FC<SelectTrackingNumberOptionProps> = (props: SelectTrackingNumberOptionProps) => {

  return (
    <Box className={props.bodyClassName} id="scroller">
      <Container>
        <Grid container className={props.mainGridContainerClassName}>
          <Grid xs={12} style={{ zIndex: 2 }} item>

            <Box>
              <Paper className={props.boxInnerClassName}>
                <Box className={props.headerClassName}>
                  <Box className={props.logoClassName}>
                    {props.isMediumScreen ? "" : <BackButton className={props.backButtonClassName} />}
                    <img src={props.logoImage} alt="GoGoGo logo" />
                  </Box>
                </Box>

                <div className={props.containerInfoClassName}>

                  <Grid direction="row" container className={props.infoClassName}>
                    <Grid xs={12} sm={12} item style={{ display: "flex", }}>
                      <DuplicateTrackingNumberList details={props.duplicateData} carrier={props.carrier} trackingNumber={Number(props.trackingNumber)} />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default SelectTrackingNumberOption;