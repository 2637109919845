import { combineReducers } from "redux";
// import appReducer from "./app/app.reducer";
import trackingReducer from "./tracking/tracking.reducer";

const rootReducer = combineReducers({
  tracking: trackingReducer,
  // app: appReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;

export default rootReducer;
