const createAddressString = (
  City: string | null | undefined,
  StateOrProvinceCode: string | null | undefined,
  PostalCode: string | null | undefined,
  Country: string | null | undefined
) => {
  if (City && StateOrProvinceCode && Country) {
    return `${City}, ${StateOrProvinceCode} ${Country}`;
  }
  if (City && PostalCode && Country) {
    return `${City}, ${PostalCode} ${Country}`;
  }
  if (City && Country) {
    return `${City}, ${Country}`;
  }
  return "Unknown";
};

export default createAddressString;
