import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { LinearProgress } from "@material-ui/core";
import Tracking from "./pages/Tracking/Tracking";
import Searching from './pages/Search/Searching';
import ReceiptPage from "./pages/Receipts/ReceiptPage";
import FeedbackReceived from "./pages/FeedbackReceived";


const Router: React.FC = React.memo(() => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <Route exact path="/" render={() => <Searching />} />
        <Route exact path={'/ok'}>
          <FeedbackReceived happy={true} />
        </Route>
        <Route exact path={'/thanks'}>
          <FeedbackReceived happy={false} />
        </Route>
        <Route path={"/dropoffs/:id"}>
          <ReceiptPage />
        </Route>
        <Route path={"/:carrier?/:number/:identifier?"} render={() => <Tracking />} />
      </Switch>
    </Suspense>
  );
});

export default Router;
