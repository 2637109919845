import { Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, FormGroup, FormControlLabel, Checkbox, TextField, Button, DialogActions, Slide, LinearProgress } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { FC, forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FeedbackReason, SaveDropOffRequestParams, saveFeedbackAsync } from "../../utils/network";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type FeedbackFormProps = {
  dialogFormText: string;
  dialogTitle: string;
  dropOffID: string;
  fullScreen: boolean;
  onCancelClicked: VoidFunction;
  onCloseDialog: VoidFunction;
  open: boolean;
  sentiment: "sad" | "happy"
}


const FeedbackForm: FC<FeedbackFormProps> = (props) => {
  const [reasons, setReasons] = useState<FeedbackReason[]>([]);
  const [progress, setProgress] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");
  const history = useHistory();

  const setFeedbackReason = (reason: FeedbackReason) => {
    const reasonSet = new Set(reasons);
    reasonSet.add(reason);
    setReasons(Array.from(reasonSet));
  }

  const sendFeedbackAsync = async () => {
    const data: SaveDropOffRequestParams = {
      reasons,
      notes,
      dropoffId: props.dropOffID,
      sentiment: props.sentiment
    }

    try {
      setProgress(true);
      const response = await saveFeedbackAsync(data);
      if (response?.data?.result === "OK") {
        const params = new URLSearchParams();
        params.append('id', props.dropOffID);
        if (props.sentiment === "happy") {
          history.push({search: params.toString(), pathname: '/ok'});
        } else {
          history.push('/thanks');
        }
      }
    }
    catch {

    }
  }

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullScreen={props.fullScreen}
      >
        <DialogTitle id="alert-dialog-slide-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.dialogFormText}
          </DialogContentText>
          <form style={{ width: '40rem', maxWidth: '100%' }}>
            {
              progress ? <LinearProgress /> : null
            }
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox name="customer_service" color="primary" />}
                  label="Customer Service"
                  onClick={() => setFeedbackReason("customerService")}
                />
                <FormControlLabel
                  control={<Checkbox name="wait_time" color="primary" />}
                  label="Wait Time"
                  onClick={() => setFeedbackReason("waitTime")}
                />
                <FormControlLabel
                  control={<Checkbox name="environment" color="primary" />}
                  label="Environment"
                  onClick={() => setFeedbackReason("environment")}
                />
                <FormControlLabel
                  control={<Checkbox name="other" color="primary" />}
                  label="Other"
                  onClick={() => setFeedbackReason("other")}
                />
                <TextField
                  multiline
                  minRows={6}
                  variant="outlined"
                  placeholder="Let us know more"
                  onChange={(e) => setNotes(e.target.value)} />
              </FormGroup>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancelClicked} color="primary">
            Cancel
          </Button>
          <Button onClick={() => (sendFeedbackAsync())} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default FeedbackForm;
