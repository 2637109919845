import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { AxiosResponse } from "axios";
import { ResponseType } from "../../types/Response";

import {
  IconPreTransit,
  IconDelayed,
  IconDelivered,
  UpArrow,
  InTransitIcon2,
  InTransitIcon2Rotate,
  ExceptionIcon2,
} from "../../assets/images/icons";
import logoImg from "../../assets/images/logo.png";
import DHLLogoImg from "../../assets/images/dhl-express.png";
import FedExLogoImg from "../../assets/images/fedex.png";
import UPSLogoImg from "../../assets/images/ups-logo.svg";
import TrackingStyles from "./TrackingStyles";
import trackApi from "../../api/track.api";
import ITrack, { CarrierNames, StatusEnum } from "../../types/Track";
import getDeliveryDate from "../../utils/getDeliveryDate";
import TrackingList from "../../components/TrackList/TrackList";
import createAddressString from "../../utils/createAddressString";
import TrackingNumberError from "../../components/TrackingNumberError";
import SelectTrackingNumberOption from "../../components/SelectTrackingNumberOption";
import BackButton from "../../components/BackButton";

type UrlParams = {
  number: string;
  carrier: CarrierNames;
  identifier: string;
};

const Tracking = React.memo(() => {
  const classes = TrackingStyles();

  const params = useParams<UrlParams>();

  const { number: trackId, carrier, identifier } = params;

  const mobileQuery = useMediaQuery("(max-width:600px)");
  const mediumQuery = useMediaQuery("(max-width:767px)");
  const largeQuery = useMediaQuery("(max-width:1100px)");

  const [activeStep, setActiveStep] = useState(0);
  const [mixedStep, setMixedStep] = useState("Out For Delivery");
  const [track, setTrack] = useState<ITrack | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [duplicateData, setDuplicateData] = useState<ITrack[]>([]);
  const [steps, setSteps] = useState([
    "Pre-Transit",
    "In Transit",
    "Out for Delivery",
    "Delivered",
  ]);
  const scrollToTop = () => {
    document.getElementById("scroller")?.scroll({ top: 0, behavior: "smooth" })
  };

  /**
   * Returns the actual tracking information from the multiship API response. This
   * is meant to be chained to an async function that gets tracking information.
   */
  const getTrackingDataFromResponse = async (response: AxiosResponse<ResponseType>) => {
    return response.data.Tracking;
  };

  const getCarrierLogo = (carrier?: CarrierNames): string => {
    const dict: { [key in CarrierNames]: string } = {
      FedEx: FedExLogoImg,
      DHL: DHLLogoImg,
      UPS: UPSLogoImg,
    };
    if (!carrier) {
      return "";
    }
    return dict[carrier];
  };

  const StepIcon = (props: StepIconProps) => {
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <IconPreTransit />,
      2: <InTransitIcon2 />,
      3: <IconDelayed />,
      4: <IconDelivered />,
    };

    const iconsReturn: { [index: string]: React.ReactElement } = {
      1: <IconPreTransit />,
      2: <InTransitIcon2 />,
      3: <InTransitIcon2Rotate />,
      4: <IconDelivered />,
    };

    const iconsException: { [index: string]: React.ReactElement } = {
      1: <IconPreTransit />,
      2: <InTransitIcon2 />,
      3: <ExceptionIcon2 />,
      4: <IconDelivered />,
    };

    const iconsErrorException: { [index: string]: React.ReactElement } = {
      1: <IconPreTransit />,
      2: <InTransitIcon2 />,
      3: (
        <ErrorOutlineIcon
          fontSize="large"
          style={{ margin: "-8px 0 0 -7px" }}
        />
      ),
      4: <IconDelivered />,
    };

    if (mixedStep === "Return To Sender") {
      return (
        <div
          className={cn(classes.stepIconRoot, {
            [classes.stepIconActive]: active,
            [classes.stepIconCompleted]: completed,
          })}
        >
          {iconsReturn[String(props.icon)]}
        </div>
      )
    } else if (mixedStep === "Exception") {
      return (
        <div
          className={cn(classes.stepIconRoot, {
            [classes.stepIconActive]: active,
            [classes.stepIconCompleted]: completed,
          })}
        >
          {iconsException[String(props.icon)]}
        </div>
      )
    } else if (mixedStep === "Error") {
      return (
        <div
          className={cn(classes.stepIconRoot, {
            [classes.stepIconActive]: active,
            [classes.stepIconCompleted]: completed,
          })}
        >
          {iconsErrorException[String(props.icon)]}
        </div>
      )
    } else {
      return (
        <div
          className={cn(classes.stepIconRoot, {
            [classes.stepIconActive]: active,
            [classes.stepIconCompleted]: completed,
          })}
        >
          {icons[String(props.icon)]}
        </div>
      );
    }
  }

  useEffect(() => {
    setError(null);
    setDuplicateData([]);
    if (trackId) {
      trackApi.getTrackingInformationFromCarrier(trackId, carrier || "", identifier || '')
        .then(getTrackingDataFromResponse)
        .then((trackingData) => {
          if (!trackingData || !trackingData.length) {
            setError("Not found information from this tracking number");
            //setError("No tracking information found at this URL");
            //setError("Wrong request. This link may be broken");
          } else if (trackingData.length > 1) {
            setDuplicateData(trackingData);
          } else {
            setTrack(trackingData[0]);
          }
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [trackId, carrier, identifier]);

  useEffect(() => {
    if (track) {
      const statusCode = track.StatusCode;
      const stateSteps = [...steps];

      switch (statusCode) {
        case StatusEnum.PreTransit:
          setActiveStep(0);
          break;
        case StatusEnum.Cancelled:
          setActiveStep(0);
          stateSteps[0] = StatusEnum.Cancelled;
          setSteps(stateSteps);
          break;
        case StatusEnum.InTransit:
          setActiveStep(1);
          break;
        case StatusEnum.OutForDelivery:
          setActiveStep(2);
          break;
        case StatusEnum.Exception:
          setActiveStep(2);
          setMixedStep(StatusEnum.Exception);
          break;
        case StatusEnum.Error:
          setActiveStep(2);
          setMixedStep(StatusEnum.Error);
          break;
        case StatusEnum.Delayed:
          setActiveStep(2);
          setMixedStep(StatusEnum.Delayed);
          break;
        case StatusEnum.ReturnToSender:
          setActiveStep(2);
          setMixedStep("Return To Sender");
          // stateSteps[stateSteps.length - 1] = StatusEnum.ReturnToSender;
          // setSteps(stateSteps);
          break;
        case StatusEnum.Delivered:
          setActiveStep(3);
          break;
        case StatusEnum.AvailableForPickup:
          setActiveStep(3);
          break;
        default:
          setActiveStep(0);
      }
    }
  }, [track, steps]);

  if (!trackId) {
    return <div>{/* {<Searching />} */}</div>;
  }

  if (duplicateData.length) {
    return (
      <SelectTrackingNumberOption
        isMediumScreen={mediumQuery}
        duplicateData={duplicateData}
        carrier={carrier}
        trackingNumber={trackId}
        logoImage={logoImg}
        bodyClassName={classes.body}
        mainGridContainerClassName={classes.mainGridContainer}
        boxInnerClassName={classes.boxInner}
        headerClassName={classes.header}
        logoClassName={classes.logo}
        backButtonClassName={classes.backBtn}
        containerInfoClassName={classes.containerInfo}
        infoClassName={classes.preInfo} />
    )
  }

  if (!track && !error) {
    return (
      <Box className={classes.body}>
        <CircularProgress className={classes.preloader} color="primary" />
      </Box>
    );
  }

  if (!track && error) {
    return (
      <TrackingNumberError
        boxClassName={classes.body}
        containerClassName={classes.errorContainer}
        errorMessageClassName={classes.errorMessage}
        error={error} />
    );
  }

  if (track) {
    const countTrack: boolean = track.TrackingNumber.length > 15;
    //change
    //const countTrack = (track.TrackingNumber.length > 15 ? true : false );
    return (
      <Box className={classes.body} id="scroller">
        <Container>
          <Grid container spacing={4} className={classes.mainGridContainer}>
            <Grid xs={12} style={{ zIndex: 2 }} item>

              <Box
              // className={cn(classes.view, {
              //   [classes.visible]: activeView === 0,
              // })}
              >
                <Paper className={classes.boxInner}>
                  <Box className={classes.header}>
                    <Box className={classes.logo}>
                      {mediumQuery ? "" : <BackButton className={classes.backBtn} />}
                      <img src={logoImg} alt="GoGoGo logo" />
                    </Box>
                  </Box>
                  {/* { mediumQuery ? backButton() : "" } */}
                  <Stepper
                    activeStep={activeStep}
                    orientation={"horizontal"}
                    alternativeLabel={true}
                    className={`${classes.stepper} animated-stepper-active-line`}
                  >
                    {steps.map((label, i) => (
                      <Step key={label}
                        style={{ position: "relative" }}
                        className={cn(classes.stepItem, {
                          [classes.stepItemDelivered]:
                            i === 3 && activeStep === 3,
                          [classes.stepErrorLineGradient1]:
                            i === 3 &&
                            activeStep === 2 &&
                            mixedStep !== "Out For Delivery" &&
                            mixedStep !== "Delayed" &&
                            mixedStep !== "Exception",
                          [classes.stepErrorLineGradient2]:
                            i === 2 &&
                            activeStep === 2 &&
                            mixedStep !== "Out For Delivery" &&
                            mixedStep !== "Delayed" &&
                            mixedStep !== "Exception",
                          [classes.stepItemLineGradient]:
                            i === activeStep + 1,
                        })}>
                        {mediumQuery ? i === 0 ? <BackButton className={classes.backBtn} /> : "" : ""}
                        <StepLabel
                          className={cn(classes.stepLabel, {
                            [classes.stepCompleted]:
                              i === 3 && activeStep === 3,
                            [classes.stepError]:
                              i === 2 &&
                              activeStep === 2 &&
                              mixedStep !== "Out For Delivery" &&
                              mixedStep !== "Delayed" &&
                              mixedStep !== "Exception",
                          })}

                          // StepIconComponent={mixedStep === "Return To Sender" ? StepIconReturn : StepIcon}

                          StepIconComponent={StepIcon}

                        >
                          {i === 2 ? mixedStep : label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div className={classes.containerInfo}>

                    <Grid direction="row" container className={classes.preInfo}>

                      <Grid xs={12} sm={3} item style={{ display: "flex", }}>
                        <Grid xs={12} sm={12} item className={classes.carrierLogo}>
                          <img
                            src={getCarrierLogo(track.ShipmentInfo?.Carrier)}
                            alt="carrier"
                          />
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={9} item style={{ paddingRight: 0 }} direction="row" container>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          direction="column" container
                          className={classes.trackingNumber}
                          style={{ justifyContent: "space-between" }}
                        >

                          <Grid className={classes.trackingNumberItem1}>
                            <Typography variant="body1" style={{ color: "#666666" }} >Tracking number</Typography>
                            <Typography variant={largeQuery ? "h3" : "h2"}
                              style={countTrack ? largeQuery ? { fontSize: 20, wordWrap: "break-word" } : { wordWrap: "break-word" } : { wordWrap: "break-word" }}>
                              {track.TrackingNumber}
                            </Typography>
                          </Grid>

                          <Grid className={classes.trackingNumberItem2}>
                            <Typography variant="body1" style={{ color: "#666666" }} >Service</Typography>
                            <Typography variant={mobileQuery ? "h3" : "h2"} >
                              {track.ShipmentInfo?.ServiceInfo}
                            </Typography>

                          </Grid>

                        </Grid>

                        <Grid direction="column" item xs={12} sm={3} container
                          style={{ justifyContent: "space-between", }}>

                          {getDeliveryDate(track) !== 'Unknown'
                            ? <Grid className={classes.trackingItem}>
                              <Typography variant="body1" style={{ color: "#666666", marginBottom: 0 }}>
                                {track.DeliveryTimestamp
                                  ? "Delivered On"
                                  : "Estimated Delivery"}
                              </Typography>
                              <Typography variant={mobileQuery ? "h4" : "h3"} style={{ whiteSpace: "nowrap", }}>
                                {getDeliveryDate(track)}
                              </Typography>
                            </Grid>
                            : <span />
                          }

                          <Grid
                            className={cn(classes.trackingItem)}
                            style={{ color: "#666666" }}
                          >
                            <Typography
                              variant="body1"
                              style={{ color: "#666666", marginBottom: 0 }}
                            >
                              Origin
                            </Typography>
                            <Typography variant="h4">
                              {createAddressString(
                                track.ShipmentInfo?.ShipperAddress?.City,
                                track.ShipmentInfo?.ShipperAddress
                                  ?.StateOrProvinceCode,
                                track.ShipmentInfo?.ShipperAddress?.PostalCode,
                                track.ShipmentInfo?.ShipperAddress?.Country
                              )}
                            </Typography>
                          </Grid>

                        </Grid>
                        <Grid
                          item
                          direction="column"
                          xs={12}
                          sm={3}
                          container
                          style={{ justifyContent: "flex-end" }}
                        >
                          <Grid className={classes.trackingItem}>
                            <Typography
                              variant="body1"
                              style={{ color: "#666666", marginBottom: 0 }}
                            >
                              Destination
                            </Typography>
                            <Typography variant="h4">
                              {createAddressString(
                                track.ShipmentInfo?.DestinationAddress?.City,
                                track.ShipmentInfo?.DestinationAddress
                                  ?.StateOrProvinceCode,
                                track.ShipmentInfo?.DestinationAddress
                                  ?.PostalCode,
                                track.ShipmentInfo?.DestinationAddress?.Country
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Box>
            </Grid>
            <Grid xs={12} item
            // className={cn(classes.view, {
            //       [classes.visible]: activeView === 1,
            //     })}
            >
              <Paper className={classes.trackingListInner}>
                {/* <hr/>
                <Box className={classes.afterWords}>
                    {`Last updated: ${getFullDateFormat(
                      track.TrackingEvents
                        ? track.TrackingEvents[track.TrackingEvents.length - 1]
                            .DateTime
                        : ""
                    )}`}
                </Box> */}
                <TrackingList track={track} />
                <hr />
                <button className={classes.backToTop} onClick={scrollToTop} title="Up"> <UpArrow /> </button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        {/* Chat
        <Fab className={classes.message} color="secondary" aria-label="add">
          <AiOutlineMessage />
        </Fab> */}
      </Box>
    );
  }

  return <div />;
});

export default Tracking;
