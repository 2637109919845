import { makeStyles } from "@material-ui/core/styles";
import { helper } from "../../theme/theme";

const TrackingListStyles = makeStyles((theme) => ({
  trackListContainer: {
    // position: "relative",
    // height: 490,
    // maxWidth: 500,
    margin: "0 auto",
    transition: "0.5s",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      // maxHeight: 490,
    },
  },
  trackList: {
    // position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    // overflowY: "scroll",
    perspective: "700px",
    transition: "0.5s",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    // padding: `${theme.spacing(20)}px 0`,
    padding: "unset",
  },
  containerBlur: {
    height: theme.spacing(2),
    position: "absolute",
    left: 0,
    width: "100%",
  },
  topContainerBlur: {
    background: `linear-gradient(to top, ${helper.palette.primary.mainOpacity} 0%, ${theme.palette.primary.main} 100%)`,
    top: 0,
  },
  bottomContainerBlur: {
    background: `linear-gradient(to bottom, ${helper.palette.primary.mainOpacity} 0%, ${theme.palette.primary.main} 100%)`,
    bottom: 0,
  },
  navArrow: {
    color: "white",
    fontSize: 30,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    transition: "0.5s",
  },
  navArrowUp: {
    top: "-50px",
    [theme.breakpoints.down("sm")]: {
      top: "0px",
    },
  },
  navArrowDown: {
    bottom: "-50px",
    [theme.breakpoints.down("sm")]: {
      bottom: "-20px",
    },
  },
  card: {
    marginBottom: theme.spacing(3),
    transition: "0.5s",
    boxShadow: theme.shadows[4],
    [theme.breakpoints.down("sm")]: {
      boxShadow: theme.shadows[0],
    },
  },
  cardHeader: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    "& svg": {
      marginRight: theme.spacing(2),
      fontSize: 22,
    },
  },
  cardBody: {
    padding: 10,
    paddingLeft: 120,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 100,
    },
    [theme.breakpoints.down("xs")]: {
      // padding: 15,
      paddingLeft: 15,
      "& p": {
        fontSize: 13,
      },
    },
  },
  tableItem: {
    marginBottom: 11,
    backgroundColor: "#ffffff",
    boxShadow: theme.shadows[7],
    borderRadius: "6px 6px 0px 0px",
    // borderTop: `1px solid ${theme.palette.divider}`,
    // borderLeft: `1px solid ${theme.palette.divider}`,
    // borderRight: `1px solid ${theme.palette.divider}`,
  },

  tableHeader: {
    backgroundColor: "#f9f9f9",
    padding: "12px 90px",
    borderBottom: `1px solid #EFEFEE`,
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "12px 70px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "12px",
      "& h2": {
        fontSize: 18,
      },
    },
    "& svg": {
      marginRight: theme.spacing(2),
      fontSize: 22,
    },
  },
  tableStatus: {
    display: "flex",
  },
  statusTime: {
    minWidth: 65,
    maxWidth: 65,
    textAlign: "right",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      minWidth: 53,
      maxWidth: 53,
    }
  },
  statusIcon: {
    margin: "0px 15px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 5px",
    },
  },
  activeFormBtn: {
    width: 100,
    height: "60px",
    color: "#f9f9f9 !important",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& a:-webkit-any-link": {
      color: "#fff",
    },
    "& button": {
      width: 100,
      height: "100%",
      borderRadius: 25,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: 55
      },
    }
  },
}));

export default TrackingListStyles;
