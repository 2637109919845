

export const getTimeFormat = (string: string): string => {
  const date = new Date(string)
  const options: Intl.DateTimeFormatOptions = {'hour': 'numeric', 'minute': 'numeric', 'hour12': true};
  const time = new Intl.DateTimeFormat([], options).format(date);
  return time;
};

export const getFullDateFormat = (string: string): string => {
  const date = new Date(string);
  const options: Intl.DateTimeFormatOptions = {'month': 'short', 'day': 'numeric', 'hour': 'numeric', 'minute': 'numeric', 'hour12': true}
  return new Intl.DateTimeFormat([], options).format(date);
};

export const getDateFormat = (dateString: string): string => {
  const [year, month, day] = dateString.split('-');
  const date = new Date(Number(year), Number(month) - 1, Number(day))
  const options: Intl.DateTimeFormatOptions = {'month': 'long', 'year': 'numeric', 'day': 'numeric', 'weekday': 'long'}
  return new Intl.DateTimeFormat([], options).format(date);
};

// export const getStraightDate = (string: string): string => {
//   console.log(string)
//   const parsedString = string.split("-");
//   const day = parsedString[2];
//   const getMonth = +parsedString[1];
//   const year = parsedString[0];
//   const month = moment().month(Number(getMonth)-1).format("MMM");
//   return `${month} ${day}, ${year}`;
// };
