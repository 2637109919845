import { Box, Container, Grid, Paper, useMediaQuery } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import logoImg from "../../assets/images/logo.png";
import { PageState } from "../../utils/enums";
import { DropOffTransactionDetailResponse, getDropOffDetailsAsync } from "../../utils/network";
import { useParams } from "react-router-dom";
import DropOffReceiptInfo from "../../components/DropOffReceipt/DropOffReceiptInfo";
import { dropOffReceiptStyles } from "../../components/Styles/DropOffReceiptStyles";
import theme from "../../theme/theme";
import UserFeedbackSentimentBanner from "../../components/DropOffReceipt/UserFeedbackSentimentBanner";
import FeedbackForm from "../../components/DropOffReceipt/FeedbackForm";

type DropOffReceiptPageParams = {
  id: string;
}

const ReceiptPage: FC = () => {
  const classes = dropOffReceiptStyles();
  const [stateMachine, changeState] = useState<PageState>(PageState.NONE);
  const [dropOffData, setDropOffData] = useState<DropOffTransactionDetailResponse>();
  const { id } = useParams<DropOffReceiptPageParams>();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dropOffDate = dropOffData ? Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', weekday: 'short', year: 'numeric', hour: 'numeric', minute: '2-digit' }).format(new Date(dropOffData.DateTime)) : ''

  useEffect(() => {
    const getDropOffData = async () => {
      const result = await getDropOffDetailsAsync(id);
      setDropOffData(result);
    }
    getDropOffData();
  }, [id])

  return (
    <Container>
      <Box className={classes.searchInner}>
        <Box className={classes.header}>
          <Box className={classes.logo}>
            <img src={logoImg} alt="GoGoGo logo" />
          </Box>
        </Box>
      </Box>

      <Grid container justifyContent="center">
        <UserFeedbackSentimentBanner
          onHappyFeedback={() => changeState(PageState.GOOD_REVIEW)}
          onSadFeedback={() => changeState(PageState.BAD_REVIEW)} />
      </Grid>

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box display={'flex'} justifyContent={'center'}>
            <Box className={classes.receiptWrapper}>
              <Paper>
                <div style={{ padding: '1rem' }}>
                  <DropOffReceiptInfo
                    dropoffId={dropOffData?.ID || ''}
                    dropoffDate={dropOffDate}
                    totalPieceCounts={dropOffData?.TotalPieceCount || 0}
                    shipments={dropOffData?.Shipments || []} />
                </div>
              </Paper>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <div>
            <Box display={'flex'}>
              <Box display={'flex'} justifyContent={'center'} style={{ width: '100%', marginBottom: '1.875rem' }}>
                <address className={classes.address}>
                  <span className={classes.bold}>GoGoGo</span><br />
                  7500 NW 25th St #100<br />
                  Miami, FL 33122<br />
                  <a href="mailto:info@gogogoshipping.com">info@gogogoshipping.com</a>
                  <br />
                  +1 (305) 599-2003
                </address>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>

      {
        dropOffData && dropOffData.ID ?
          (<FeedbackForm
            dialogFormText={"Thats great! Tell us what we got right."}
            dialogTitle={"Tell us what we got right"}
            fullScreen={fullScreen}
            onCancelClicked={() => changeState(PageState.NONE)}
            onCloseDialog={() => changeState(PageState.NONE)}
            open={stateMachine === PageState.GOOD_REVIEW}
            dropOffID={dropOffData.ID}
            sentiment={"happy"} />) :
          null
      }

      {
        dropOffData && dropOffData.ID ?
          (<FeedbackForm
            dialogFormText={"We're really sorry. Tell us what went wrong."}
            dialogTitle={"We are really sorry"}
            fullScreen={fullScreen}
            onCancelClicked={() => changeState(PageState.NONE)}
            onCloseDialog={() => changeState(PageState.NONE)}
            open={stateMachine === PageState.BAD_REVIEW}
            dropOffID={dropOffData.ID}
            sentiment={"sad"} />) :
          null
      }
    </Container>
  )
}

export default ReceiptPage