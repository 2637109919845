import axios, { CancelToken } from "axios";

type CarrierCode = "DHL" | "FedEx";

type SaveFeedbackResponseError = {
    code: number;
    message: string;
}

type SaveFeedbackResponse = {
    id?: string;
    jsonrpc?: string;
    error?: SaveFeedbackResponseError;
    result?: "OK"
}

export type DropOffTransactionDetailShipments = {
    CarrierDivision: string;
    TrackingNumber: string;
    Carrier: CarrierCode;
    PieceIDs: string[];
}

export type FeedbackReason = "waitTime" | "customerService" | "environment" | "other";

export type SaveDropOffRequestParams ={
    dropoffId: string;
    sentiment: string;
    reasons: FeedbackReason[];
    notes?: string;
}

export type DropOffTransactionDetailResponse = {
    ID: string;
    TotalPieceCount: number;
    Status: "Submitted" | "Deleted";
    Carrier: CarrierCode;
    CarrierDivision: string;
    MixedCarriers: boolean;
    MixedCarrierDivisions: boolean;
    DateTime: string;
    Shipments: DropOffTransactionDetailShipments[];
    RequestDebugID: string;
}

/**
 * Returns the details of a drop off but by using the public endpoint.
 * @param id 
 * @param cancelToken 
 * @returns 
 */
 const getDropOffDetailsAsync = async (id: string, cancelToken?: CancelToken) => {
    const params = { APIKey: 'gogogoshipping.com-fb441bb86161debd71aaccde914b4af2' }
    const response = await axios.get<DropOffTransactionDetailResponse>(
        `https://api.gogogoshipping.com/dropoffs/transactions/${id}`,
        { cancelToken, params }
    )
    return response.data;
}

const saveFeedbackAsync = async (data: SaveDropOffRequestParams, cancelToken?: CancelToken) => {
    const response = await axios.post<SaveFeedbackResponse>('https://goship.cc/api/dropoffFeedback', data);
    return response;
}

export { getDropOffDetailsAsync, saveFeedbackAsync };
