import React from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { InfoOutlined } from "@material-ui/icons";
import { StatusEnum } from "../types/Track";
import theme from "../theme/theme";

import {
  PreTransitIcon,
  InTransitIcon,
  DeliveredIcon,
  DelayedIcon,
  ExceptionIcon,
  ErrorIcon,
  UnknownIcon,
  CancelledIcon,
  ReturnToSenderIcon,
  AvailableForPickupIcon,
  OutForDeliveryIcon,
} from "../assets/images/icons";

type TrackStatusIconType = {
  status: StatusEnum;
  color?: string;
};

const TrackStatusIcon: React.FC<TrackStatusIconType> = (
  props: TrackStatusIconType
) => {
  const { status, color = theme.palette.text.primary } = props;

  switch (status) {
    case StatusEnum.InTransit:
      return <InTransitIcon color={color} />;

    case StatusEnum.PreTransit:
      return <PreTransitIcon color={color} />;

    case StatusEnum.OutForDelivery:
      return <OutForDeliveryIcon color={color} />;

    case StatusEnum.Delayed:
      return <DelayedIcon color={color} />;

    case StatusEnum.Delivered:
      return <DeliveredIcon color={color} />;

    case StatusEnum.AvailableForPickup:
      return <AvailableForPickupIcon color={color} />;

    case StatusEnum.Exception:
      return <ExceptionIcon color={color} />;

    case StatusEnum.Error:
      return <ErrorIcon color={color} />;

    case StatusEnum.Unknown:
      return <UnknownIcon color={color} />;

    case StatusEnum.Cancelled:
      return <CancelledIcon color={color} />;

    case StatusEnum.ReturnToSender:
      return <ReturnToSenderIcon color={color} />;

    case StatusEnum.StatusInfo:
      return <InfoOutlined />;

    default:
      return <FaRegQuestionCircle color={color} />;
  }
};

export default TrackStatusIcon;
