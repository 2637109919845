import React, { FC } from "react";
import { DropOffTransactionDetailShipments } from "../../utils/network";
import { readableServiceName } from "../../utils/serviceName";
import { dropOffReceiptStyles } from "../Styles/DropOffReceiptStyles";

type TrackingDetailsProps = {
    shipments: DropOffTransactionDetailShipments[]
}

const TrackingNumber = (shipment: DropOffTransactionDetailShipments, className: string) => {

    if (shipment.PieceIDs?.length) {
        return <span>{shipment.TrackingNumber}</span>;
    }

    return (
        <div className={className} style={{marginBottom: "0.875rem"}}>
            <a href={`https://track.gogogoshipping.com/FedEx/${shipment.TrackingNumber}`}>
                {shipment.TrackingNumber}
            </a>
        </div>
    )
}

const DHLPieceNumber = (shipment: DropOffTransactionDetailShipments, ellipsisClassName: string, listClassName: string) => {
    if (shipment?.PieceIDs?.length) {
        const pieceList = shipment.PieceIDs.map((piece) => {
            return (
                <li>
                    <div className={ellipsisClassName}>
                        <a href={`https://track.gogogoshipping.com/DHL/${piece}`}>
                            {piece}
                        </a>
                    </div>
                </li>
            )
        });
        return (
            <ul className={listClassName}>
                {pieceList}
            </ul>
        )
    }
    return null;
}


const TrackingDetails: FC<TrackingDetailsProps> = (props) => {
    const classes = dropOffReceiptStyles();
    const {shipments} = props;

    const rows = shipments.map((shipment) => {
        return (
            <div className={classes.divTr}>
                <div className={classes.divTd}>
                    {TrackingNumber(shipment, classes.ellipsize)}
                    {DHLPieceNumber(shipment, classes.ellipsize, classes.pieceList)}
                </div>
                <div className={classes.divTd}>
                    {readableServiceName(shipment.CarrierDivision)}
                </div>
            </div>
        )
    });

    return (
        <div className={classes.divTbody}>
            {rows}
        </div>
    )
}

export default TrackingDetails;
