import { Grid, Box, Typography, IconButton } from "@material-ui/core";
import { SentimentSatisfiedOutlined, SentimentDissatisfiedOutlined } from "@material-ui/icons";
import React, { FC } from "react";
import { dropOffReceiptStyles } from "../Styles/DropOffReceiptStyles";

type UserFeedbackSentimentBannerProps = {
  onHappyFeedback: VoidFunction;
  onSadFeedback: VoidFunction;
}

const UserFeedbackSentimentBanner: FC<UserFeedbackSentimentBannerProps> = (props) => {
  const classes = dropOffReceiptStyles();

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Box display={'flex'} justifyContent={'center'}>
        <Typography variant="h3" component={'h1'} align={'center'}>How was your experience?</Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <div className={classes.sentiment}>
          <IconButton
            aria-label="I'm satisfied with GoGoGo's service"
            onClick={props.onHappyFeedback}
          >
            <SentimentSatisfiedOutlined style={{ fontSize: 64, color: '#071d49' }} />
          </IconButton>
        </div>
        <div className={classes.sentiment}>
          <IconButton
            aria-label="I'm dissatisfied with GoGoGo's service"
            onClick={props.onSadFeedback}
          >
            <SentimentDissatisfiedOutlined style={{ fontSize: 64, color: '#071d49' }} />
          </IconButton>
        </div>
      </Box>
    </Grid>
  )
}

export default UserFeedbackSentimentBanner;
