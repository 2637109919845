import React from "react";
import { Typography, Box } from "@material-ui/core";
import { getDateFormat, getTimeFormat } from "../../utils/date";
import TrackStatusIcon from "../TrackStatusIcon";
import trackStatusColor from "../../utils/trackStatusColor";
import TrackingListStyles from "../Styles/TrackingListStyles";
import ITrack from "../../types/Track";

import sortForStatusInfo from "../../utils/sortForStatusInfo";
import { Link } from "react-router-dom";

interface ITrackingList {
  track: ITrack | null;
}

const TrackingList: React.FC<ITrackingList> = (props: ITrackingList) => {
  const { track } = props;
  const classes = TrackingListStyles();
 // const events = (track && sortForStatusInfo(track.TrackingEvents)) || [];


  const eventItemDiv = (date: any, events: any) => {
    return (
      <div className={classes.tableItem} key={date}>
        <Box className={classes.tableHeader}>
          <Typography variant="h2">
            {getDateFormat(date)}
          </Typography>
        </Box>
        <Box style={{ padding:"10px 0px" }}>
          {events.map((event: any, index: number) => {
            return <Box className={classes.cardBody} key={index}>
              <Box className={classes.tableStatus}>
                <Typography variant="body2" className={classes.statusTime}>
                  {getTimeFormat(event.DateTime)}
                </Typography>
                <Box className={classes.statusIcon}>
                  <TrackStatusIcon
                      status={event.EventCode}
                      color={trackStatusColor(event.EventCode)}
                  />
                </Box>
                <Box>
                  <Typography variant="body2">
                    {getReturnNumberLink(event, track)}
                  </Typography>
                  <Typography variant="body2" style={{fontSize: 13, fontWeight:"normal"}}>{event.Location}</Typography>
                </Box>
              </Box>
            </Box>
          })}
        </Box>

      </div>
    );
  };

  const getStructuredEvents = () => {
    const sorted = {};
    if(track)
    {
      sortForStatusInfo(track.TrackingEvents).forEach(event => {

        const date = event.DateTime.split(event.DateTime[10])[0]
        if(sorted.hasOwnProperty(date))
        {
          // @ts-ignore
          const events = sorted[date];
          events.push(event);
          // @ts-ignore
          sorted[date] = events;
        } else {
          // @ts-ignore
          sorted[date] = [event];
        }
      })
    }
    // console.log(sorted);
    return sorted;
  }

  const getReturnNumberLink = (event:any, track:any) => {
    if (event.EventCode === "ReturnToSender") {
      if (track.ReturnTrackingNumber) {
        const descr = event.EventDescription.split("-");
        //console.log('descr', descr);
        const returnTrackNum = descr[descr.length - 1].split(':')[1];
        //console.log("returnTrackNum", returnTrackNum);
        const returnLink = () => {
          return (
            <Link to={`/${track.ShipmentInfo.Carrier}/${track.ReturnTrackingNumber}`}>{returnTrackNum}</Link>
          )
        }
        return (
          <span>
            {descr[0]} - Return tracking number: {returnLink()}
          </span>
        )
      }
      else {
        return event.EventDescription;
      }
    }
    else {
      return event.EventDescription;
    }
  }

  // const getSignatureName = (event:any) => {
  //   if(track) {

  //     if (event.EventCode === "Delivered") {
  //       if (track.DeliverySignatureName === "Signature not required") {

  //       }
  //       else if (track.DeliverySignatureName === undefined) {
  //         // return ": 'Name not specified'"
  //       }
  //       else {
  //         return (" " + track.DeliverySignatureName)
  //         // if (track.ShipmentInfo.Carrier === "DHL") {
  //         // }
  //         // else {
  //         //   return (" - Signed for by: " + track.DeliverySignatureName)
  //         // }
  //       }
  //     }
  //   }
  // }

  return (
    <Box className={classes.trackListContainer}>
      <div className={classes.trackList}>
        {Object.keys(getStructuredEvents()).map((date) => {
          // @ts-ignore
          return eventItemDiv(date, getStructuredEvents()[date], track.DeliverySignatureName);
        })}
        
        {/*{events && events.map((event) => eventItemDiv(event))}*/}

      </div>

      {/* <Box className={cn(classes.containerBlur, classes.topContainerBlur)} />
      <Box className={cn(classes.containerBlur, classes.bottomContainerBlur)} /> 
      <Box className={cn(classes.navArrow, classes.navArrowUp)}>
        <BsChevronCompactUp />
      </Box>
      <Box className={cn(classes.navArrow, classes.navArrowDown)}>
        <BsChevronCompactDown />
      </Box> */}

    </Box>
  );
};

export default TrackingList;