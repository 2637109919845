import React, { ReactElement } from "react";

export const PreTransitIcon = ({ color }: { color: string }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <rect fill="none" height="24" width="24" />
    <path
      d="M22,3.41l-5.29,5.29L20,12h-8V4l3.29,3.29L20.59,2L22,3.41z M3.41,22l5.29-5.29L12,20v-8H4l3.29,3.29L2,20.59L3.41,22z"
      fill={color}
    />
  </svg>
);

export const InTransitIcon = ({ color }: { color: string }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M14 8.83L17.17 12 14 15.17V14H6v-4h8V8.83M12 4v4H4v8h8v4l8-8-8-8z"
      fill={color}
    />
  </svg>
);

export const DelayedIcon = ({ color }: { color: string }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <g>
      <rect fill="none" height="24" width="24" x="0" />
    </g>
    <g>
      <g>
        <path
          d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8 S16.41,20,12,20z M12.5,7H11v6l5.2,3.2l0.8-1.3l-4.5-2.7V7z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export const ExceptionIcon = ({ color }: { color: string }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M3 12c0 2.21.91 4.2 2.36 5.64L3 20h6v-6l-2.24 2.24C5.68 15.15 5 13.66 5 12c0-2.61 1.67-4.83 4-5.65V4.26C5.55 5.15 3 8.27 3 12zm8 5h2v-2h-2v2zM21 4h-6v6l2.24-2.24C18.32 8.85 19 10.34 19 12c0 2.61-1.67 4.83-4 5.65v2.09c3.45-.89 6-4.01 6-7.74 0-2.21-.91-4.2-2.36-5.64L21 4zm-10 9h2V7h-2v6z"
      fill={color}
    />
  </svg>
);

export const ErrorIcon = ({ color }: { color: string }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path
      d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
      fill={color}
    />
  </svg>
);

export const UnknownIcon = ({ color }: { color: string }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
      fill={color}
    />
  </svg>
);

export const CancelledIcon = ({ color }: { color: string }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
    <path
      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"
      fill={color}
    />
  </svg>
);

export const ReturnToSenderIcon = ({
  color,
}: {
  color: string;
}): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <path d="M24,24H0L0,0l24,0V24z" fill="none" />
    <path
      d="M10,8.8V10h8v4h-8v1.2L6.8,12L10,8.8 M4,12l8,8v-4h8V8h-8V4L4,12z"
      fill={color}
    />
  </svg>
);

export const AvailableForPickupIcon = ({
  color,
}: {
  color: string;
}): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M16.49 15.5v-1.75L14 16.25l2.49 2.5V17H22v-1.5h-5.51zm3.02 4.25H14v1.5h5.51V23L22 20.5 19.51 18v1.75zM9.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM5.75 8.9L3 23h2.1l1.75-8L9 17v6h2v-7.55L8.95 13.4l.6-3C10.85 12 12.8 13 15 13v-2c-1.85 0-3.45-1-4.35-2.45l-.95-1.6C9.35 6.35 8.7 6 8 6c-.25 0-.5.05-.75.15L2 8.3V13h2V9.65l1.75-.75"
      fill={color}
    />
  </svg>
);

export const OutForDeliveryIcon = ({
  color,
}: {
  color: string;
}): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="-2 0 26 26"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5l1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
      fill={color}
    />
  </svg>
);

export const DeliveredIcon = ({ color }: { color: string }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"
      fill={color}
    />
  </svg>
);

export const IconPreTransit = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 3.41L16.71 8.7L20 12H12V4L15.29 7.29L20.59 2L22 3.41ZM3.41 22L8.7 16.71L12 20V12H4L7.29 15.29L2 20.59L3.41 22Z"/>
    </svg>
  );
}
export const IconInTransit = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"/>
    </svg>
  );
}
export const IconDelayed = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10.5 5H9V11L14.2 14.2L15 12.9L10.5 10.2V5Z"/>
    </svg>
  );
}
export const IconDelivered = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"/>
    </svg>
  );
}
export const UpArrow = () => {
  return (
    <svg version="1.1" width="17" height="17" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
      <g><path d="M67.6,487c17.3,17.3,45.6,17.3,62.9,0l324.8-324.9v783.3c0,24.7,19.9,44.6,44.6,44.6c24.7,0,44.6-19.9,44.6-44.6V162.1L869.4,487c17.3,17.3,45.6,17.3,62.9,0c17.3-17.3,17.3-45.6,0-62.9L531.5,23c-4.2-4.2-9-7.5-14.5-9.6c-5.4-2.3-11.2-3.4-16.9-3.4c-5.7,0-11.6,1.1-17.1,3.4c-5.4,2.3-10.3,5.6-14.5,9.6l-401,400.8C50.3,441.4,50.3,469.5,67.6,487L67.6,487z"/></g>
    </svg>
  )
}

export const LeftArrow = () => {
  return (
    <svg version="1.1" width="17" height="17" fill="#071D49" xmlns="http://www.w3.org/2000/svg" transform="rotate(-90)"  x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
      <g><path d="M67.6,487c17.3,17.3,45.6,17.3,62.9,0l324.8-324.9v783.3c0,24.7,19.9,44.6,44.6,44.6c24.7,0,44.6-19.9,44.6-44.6V162.1L869.4,487c17.3,17.3,45.6,17.3,62.9,0c17.3-17.3,17.3-45.6,0-62.9L531.5,23c-4.2-4.2-9-7.5-14.5-9.6c-5.4-2.3-11.2-3.4-16.9-3.4c-5.7,0-11.6,1.1-17.1,3.4c-5.4,2.3-10.3,5.6-14.5,9.6l-401,400.8C50.3,441.4,50.3,469.5,67.6,487L67.6,487z"/></g>
    </svg>
  )
}

export const InTransitIcon2 = () => {
  return (
    <svg width="24" height="24" viewBox="-1 -1 20 20" fill="" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 4.83L13.17 8L10 11.17V10H2V6H10V4.83ZM8 0V4H0V12H8V16L16 8L8 0Z"/>
    </svg>
  )
};
export const InTransitIcon2Rotate = () => {
  return (
    <svg width="24" height="24" viewBox="-4 -3 20 20" fill="" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">
      <path d="M10 4.83L13.17 8L10 11.17V10H2V6H10V4.83ZM8 0V4H0V12H8V16L16 8L8 0Z"/>
    </svg>
  )
};
export const ExceptionIcon2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 1 24 24" width="24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M3 12c0 2.21.91 4.2 2.36 5.64L3 20h6v-6l-2.24 2.24C5.68 15.15 5 13.66 5 12c0-2.61 1.67-4.83 4-5.65V4.26C5.55 5.15 3 8.27 3 12zm8 5h2v-2h-2v2zM21 4h-6v6l2.24-2.24C18.32 8.85 19 10.34 19 12c0 2.61-1.67 4.83-4 5.65v2.09c3.45-.89 6-4.01 6-7.74 0-2.21-.91-4.2-2.36-5.64L21 4zm-10 9h2V7h-2v6z"
      />
    </svg>
  )
};