import { getFullDateFormat } from "./date";
import ITrack from "../types/Track";

const getDeliveryDate = (track: ITrack): string => {
  if (track.DeliveryTimestamp) {
    return getFullDateFormat(track.DeliveryTimestamp);
  }
  if (track.EstimatedDeliveryDate) {
    return getFullDateFormat(track.EstimatedDeliveryDate);
  }
  return "Unknown";
};

export default getDeliveryDate;
