import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#071D49",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFF800",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#2F80ED",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#27AE60",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "rgba(250,250,250,1)",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#333333",
      secondary: "#BDBDBD",
    },
    divider: "#0b2a67",
  },
  
  typography: {
    htmlFontSize: 16,
    fontFamily: "'Scada', sans-serif",
    h1: {
      fontSize: 30,
      fontWeight: "bold",
      lineHeight: 1.6,
    },
    h2: {
      fontSize: 24,
      fontWeight: "normal",
      color: "#071D49",
    },
    h3: {
      fontSize: 22,
      fontWeight: "normal",
      color: "#071D49",
    },
    h4: {
      fontSize: 16,
      fontWeight: "normal",
      color: "#071D49",
    },
    subtitle1: {
      fontWeight: "bold",
      lineHeight: 1.5,
      fontSize: 16,
    },
    body1: {
      fontSize: 15,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: "bold",
    },
  },
  shape: {
    borderRadius: 4,
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 10px rgba(0, 0, 0, 0.05)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: 16,
      },
    },
  },
  props: {
    MuiPaper: {
      elevation: 4,
    },
  },
  breakpoints: {
    values: {
      xs: 500,
      sm: 767,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const helper = {
  palette: {
    primary: {
      mainOpacity: "rgba(7, 29, 73, 0)",
    },
    background: {
      defaultOpacity: "rgba(250,250,250,0)",
    },
    gray: "#EFEFEE",
  },
};
export default theme;
